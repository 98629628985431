



































































import { namespace } from 'vuex-class';
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { StoresStoreActions } from '@/store/stores.store';
import Store from '@/models/Store.model';
import { ROUTE_STORE } from '@/router/routes';
import { DealerStoreActions, DealerStoreGetters } from '@/store/dealer.store';
import Dealer from '@/models/Dealer.model';
import { NavigationMenuItems } from '@/misc/NavigationMenuItems';
import { mixins } from 'vue-class-component';
import BaseMixin from '@/misc/BaseMixin.mixins';
import { DealStoreActions } from '@/store/deal.store';
import Deal from '@/models/Deal.model';
import AxiosErrorHandlerMixin from '@/misc/AxiosErrorHandler.mixin';

const StoresStore = namespace('stores');
const DealerStore = namespace('dealer');
const DealsStore = namespace('deals');

@Component({
  components: {
    StorePropertiesComponent: () => import(
      /* webpackChunkName: "StorePropertiesComponent" */
      '@/components/StoreProperties.component.vue'
    ),
    EditDealComponent: () => import(
      /* webpackChunkName: "EditDealComponent" */
      '@/components/EditDeal.component.vue'
    ),
    DealerPropertiesComponent: () => import(
      /* webpackChunkName: "DealerPropertiesComponent" */
      '@/components/DealerProperties.component.vue'
    ),
    OpeningHoursComponent: () => import(
      /* webpackChunkName: "OpeningHoursComponent" */
      '@/components/OpeningHours.component.vue'
    ),
    ContactPersonComponent: () => import(
      /* webpackChunkName: "ContactPersonComponent" */
      '@/components/ContactPerson.component.vue'
    ),
    EditStoreComponent: () => import(
      /* webpackChunkName: "EditStoreComponent" */
      '@/components/EditStore.component.vue'
    ),
  }
})
export default class StoreView extends mixins(BaseMixin, AxiosErrorHandlerMixin) {
  @Prop({ required: true })
  public id!: string;

  @StoresStore.Action(StoresStoreActions.GET_BY_ID)
  private getStoreByIdAction!: (id: string) => Promise<Store>;

  @DealerStore.Action(DealerStoreActions.GET_BY_STORE)
  private getDealerByStoreAction!: (storeId: string) => Promise<Dealer[]>;

  @DealerStore.Getter(DealerStoreGetters.DEALER_BY_STORE)
  private dealers!: Dealer[];

  @DealsStore.Action(DealStoreActions.GET_BY_STORE)
  private getDealsByStoreAction!: (id: string) => Promise<Deal[]>;

  private showDealEditDialog: boolean = false;
  private showStoreEditDialog: boolean = false;
  private title: string = '';

  // Key of the current instance of the component (is changed to reload the entire component)
  private editDealComponentInstanceKey: number = 0;

  async created() {
    if (this.currentUser.isAdmin || this.currentUser.isStoreAdmin) {
      this.reloadDealers();
    }
  }

  /**
   * Used to only show store properties if current page is store overview page.
   */
  get showAdditionalProperties(): boolean {
    return this.$route.name === ROUTE_STORE;
  }

  private async reloadDealers() {
    try {
      const store = await this.getStoreByIdAction(this.id);
      await this.getDealerByStoreAction(this.id);
    } catch (e) {
      this.handleAxiosError(e);
    }
  }

  @Watch('$route', { immediate: true })
  private onRouteChanged() {
    this.title = this.getTitle();
  }

  get currentStoreObj(): Store | null {
    if (this.currentStore) {
      return Store.parseFromObject(this.currentStore);
    }
    return null;
  }

  /**
   * Returns title for children of store navigation item.
   * Returns empty string if no child of store is active.
   */
  private getTitle(): string {
    const storeItem = NavigationMenuItems.find(item => item.route === ROUTE_STORE);
    if (storeItem) {
      const activeItem = storeItem!.children!.find(item => item.route === this.$route.name);
      return activeItem ? `${this.$t(activeItem?.name)}` : this.$t('ROUTES.STORE').toString();
    }
    return '';
  }

  private async closeDealCreationDialog(reload: boolean) {
    this.showDealEditDialog = false;
    if (reload) {
      this.editDealComponentInstanceKey++;
      try {
        await this.getDealsByStoreAction(this.id);
      } catch (e) {
        this.handleAxiosError(e);
      }
    }
  }

  private async closeStoreDialog(reload: boolean = false) {
    this.showStoreEditDialog = false;
  }
}
